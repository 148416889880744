<script>
import ModalTableShow from "@/components/ModalTableShow.vue"

export default {
  name: "TransactionItems",
  props: ["table", "closing"],
  emits: ["reloadTable", "closeTable", "editLineNote"],
  inject: ["getByBarcode", "addQuantity", "subQuantity", "printReceiptTable"],

  components: { ModalTableShow },

  methods: {
    hasChild(id) {
      return (this.table?.relationships?.lines ?? []).some(
        (line) => String(line.attributes.parent_id) === String(id)
      )
    },

    isLineEditable(line) {
      if (line.type === 'cashinout') return false
      return line.editable
    }
  },

  computed: {
    terminal() {
      return this.$store.getters["terminal/terminal"]
    },

    transaction() {
      return this.$store.getters["transaction/transaction"]
    },

    total() {
      let total = this.table?.attributes?.total || 0

      if (this.transaction?.total && Number(this.transaction.total) > 0) {
        total += Number(this.transaction.total)
      }

      return total.toFixed(2)
    },
  },
}
</script>

<template>
  <div
    v-if="table"
    class="flex-1 w-full overflow-auto"
  >
    <div
      v-if="table.relationships?.lines.length > 0"
      class="transaction-content overflow-auto flex flex-col-reverse font-bold"
    >
      <div
        v-for="(line, index) in table.relationships.lines_consolidated.slice().reverse()"
        :key="index"
        class="transaction-line grid grid-cols-12 py-1 px-1 flex items-start"
        :class="{ selected: line.selected }"
      >
        <div class="col-span-1 flex justify-end">
          <div class="text-lg leading-5">{{ parseFloat(line.attributes.quantity) }}</div>
        </div>
        <div class="col-span-2"></div>
        <!-- <div class="col-span-2 flex justify-center"></div> -->
        <div class="col-span-5 text-left">
          <div
            class="text-base leading-5 truncate"
            :class="{ 'ml-3 italic': line.attributes.addon }"
          >
            {{ line.attributes.description }}
          </div>
          <div
            class="italic text-xs font-normal"
            v-if="line.attributes.notes"
          >
            {{ line.attributes.notes }}
          </div>
          <div
            class="italic text-xs font-normal"
            v-for="(kitchen_grocery, grocery_index) in line.attributes.kitchen_groceries"
            v-bind:key="grocery_index"
          >
            {{ grocery_index }}: {{ kitchen_grocery }}
          </div>
          <div
            class="italic text-xs ml-3 font-normal"
            v-if="line.attributes.variant_label"
          >
            {{ line.attributes.variant_label }}
          </div>
        </div>
        <!-- <div class="col-span-2 flex justify-center"></div> -->

        <a
          @click="getByBarcode(line)"
          :class="{
            'opacity-100 pointer-events-auto':
              !line.attributes.parent_id &&
              !line.attributes.addon &&
              !line.attributes.variant_id &&
              hasChild(line.id) === false &&
              line.attributes.kitchen_groceries.length == 0,
          }"
          class="col-span-1 cursor-pointer text-lg leading-5 text-gray-300 opacity-0 pointer-events-none plus-icon"
        >
          <svg
            class="h-6 w-6"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z"
            />
          </svg>
        </a>
        <div class="text-base col-span-3 flex items-center justify-end">
          <div class="flex items-center">
            <div>{{ vueNumberFormat(line.attributes.total) }}</div>
            <div
              class="text-xs text-gray-300"
              v-if="line.attributes.discount_amount > 0"
            >
              (-{{ vueNumberFormat(line.attributes.discount_amount) }})
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="transaction && transaction.lines.length > 0"
      class="transaction-content overflow-auto flex flex-col-reverse"
    >
      <div
        v-for="(line, index) in transaction.lines.slice().reverse()"
        :class="{ selected: line.selected }"
        class="transaction-line grid grid-cols-12 py-1 px-1 flex items-start new-item"
        :key="index"
      >
        <div class="col-span-1 flex justify-end">
          <div class="text-lg leading-5">{{ line.quantity }}</div>
        </div>

        <div class="col-span-2 flex justify-center">
          <a
            v-if="isLineEditable(line)"
            @click="subQuantity(line)"
            class="leading-5 px-1 text-gray-300"
          >
            <svg
              class="h-5 w-4"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3 10C3 9.44772 3.44772 9 4 9L16 9C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11L4 11C3.44772 11 3 10.5523 3 10Z"
              />
            </svg>
          </a>
        </div>
        <div class="col-span-5 text-left">
          <div
            @click="$emit('editLineNote', line)"
            class="font-bold leading-5 truncate"
            :class="{ 'ml-3 italic': line.addon || !line.editable }"
          >
            {{ line.description }}
          </div>

          <div
            class="italic text-xs"
            v-if="line.notes"
          >
            {{ line.notes }}
          </div>
          <div
            class="italic text-xs"
            v-for="(kitchen_grocery, grocery_index) in line.kitchen_groceries"
            v-bind:key="grocery_index"
          >
            {{ kitchen_grocery.description }}: {{ kitchen_grocery.value }}
          </div>
          <div
            class="italic text-xs ml-3"
            v-if="line.variant_label"
          >
            {{ line.variant_label }}
          </div>
        </div>
        <div class="col-span-1 flex justify-start">
          <a
            v-if="isLineEditable(line)"
            @click="addQuantity(line)"
            class="cursor-pointer text-lg leading-5 px-1 text-gray-300"
          >
            <svg
              class="h-5 w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z"
              />
            </svg>
          </a>
        </div>

        <div
          v-if="line.editable"
          class="col-span-3 flex items-center justify-end font-bold"
        >
          <div>
            <div>{{ vueNumberFormat(line.total) }}</div>
            <div
              class="text-xs text-gray-300"
              v-if="line.discount_amount > 0"
            >
              (-{{ vueNumberFormat(line.discount_amount) }})
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer
    v-if="table"
    class="flex-shrink-0 w-full text-white border-t table-footer mt-2 pt-2"
  >
    <div class="font-bold rounded bg-shadow flex justify-between text-lg p-2">
      <div>{{ $t("translations.views.mobilepos.pages.table.total") }}</div>
      <div>{{ vueNumberFormat(total) }}</div>
    </div>

    <div class="grid grid-cols-2 gap-3 pt-3 pb-0">
      <div class="col-span-1"></div>

      <div class="col-span-1 grid grid-cols-2 gap-3">
        <modal-table-show @reload="$emit('reloadTable')" />

        <button
          type="button"
          @click="printReceiptTable()"
          class="button-primary-outline rounded flex items-center justify-center focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
            />
          </svg>
        </button>
      </div>
    </div>

    <div
      v-if="transaction.items > 0"
      class="grid grid-cols-2 items-center gap-3 pt-3"
    >
      <button
        type="button"
        @click.prevent="$emit('closeTable')"
        :disabled="closing"
        class="button button-filled button-primary inline-flex items-center justify-center w-full"
      >
        <span
          v-if="closing"
          class="text-white"
        >
          <svg
            class="animate-spin mr-2 h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            />
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </span>
        {{ $t("translations.views.mobilepos.pages.table.save") }}
      </button>

      <router-link
        :to="{ name: 'transaction' }"
        class="button-primary-outline inline-flex justify-center w-full rounded-sm px-4 py-2"
      >
        {{ $t("translations.views.mobilepos.pages.table.pay") }}
      </router-link>
    </div>

    <div
      v-else-if="transaction.items == 0 && table.relationships.lines.length > 0"
      class="grid grid-cols-1 items-center gap-3 pt-3"
    >
      <router-link
        :to="{ name: 'transaction' }"
        class="button-primary rounded inline-flex justify-center w-full rounded-sm border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium shadow-sm focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5"
      >
        {{ $t("translations.views.mobilepos.pages.table.pay") }}
      </router-link>
    </div>
  </footer>
</template>
