<script>
import axios from "axios"
import { A11y, EffectCreative } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/swiper-vue"
import LoadingIndicator from "@/components/LoadingIndicator.vue"
import BasketItems from "@/components/mobilepos/button-view/BasketItems.vue"
import ItemSelection from "@/components/mobilepos/button-view/ItemSelection.vue"
import TransactionItems from "@/components/mobilepos/button-view/TransactionItems.vue"
import SubtableModal from "@/components/mobilepos/SubtableModal.vue"
import ModalNotes from "@/components/ModalNotes.vue"
import ModalTableRename from "@/components/ModalTableRename.vue"
import NavigationMobilePos from "@/components/NavigationMobilePos.vue"
import ItemHelper from "@/mixins/item.helper"
import PosHelper from "@/mixins/pos.helper"
import TableViewMixin from "@/mixins/table-view.mixin"

export default {
  name: "TableButton",
  props: ["area_id", "table_id"],

  mixins: [TableViewMixin, ItemHelper, PosHelper],
  components: {
    SubtableModal,
    LoadingIndicator,
    BasketItems,
    TransactionItems,
    ModalNotes,
    ModalTableRename,
    NavigationMobilePos,
    ItemSelection,
    SwiperSlide,
    Swiper,
  },

  provide() {
    return {
      getByBarcode: this.getByBarcode,
      // POS-Helper
      lineDelete: this.lineDelete,
      addQuantity: this.addQuantity,
      subQuantity: this.subQuantity,
      printReceiptTable: this.printReceiptTable,
      // Navigation
      openBasket: this.openBasket,
      openCurrentBasket: this.openCurrentBasket,
    }
  },

  mounted() {
    this.$watch(
      () => this.table_id,
      () => this.tableOpen()
    )

    this.$watch(
      () => this.area_id,
      () => this.fetchArea(),
      { immediate: true }
    )

    // @todo: remove this when the screen-table class is removed
    document.documentElement.classList.add("screen-table")
  },

  unmounted() {
    document.documentElement.classList.remove("screen-table")
  },

  data: () => ({
    area: null,
    initialitem: null,

    // Swiper
    swiper: null,
    modules: [A11y, EffectCreative],

    // POS-Helper
    ignoreGetItemsFunction: true,
  }),

  methods: {
    openBasket() {
      this.swiper?.slideTo(0, 350)
    },

    openCurrentBasket() {
      this.swiper?.slideTo(2, 350)
    },

    openLineNote(line) {
      this.$refs.modalNotes.openByLine(line)
    },

    async getByBarcode(line) {
      let barcode = line.attributes.barcode

      await axios
        .post("/items/search-barcode", {
          barcode: barcode,
        })
        .then((response) => {
          if (response.data.data) {
            this.initialitem = response.data.data
          }
        })
        .catch((error) => {
          if (error.response.status == 404) {
            this.$notify(
              {
                group: "notifications",
                type: "error",
                title: "Niet gevonden",
                text: "Gescande artikel is niet gevonden",
              },
              2000
            )
          } else {
            this.$notify(
              {
                group: "notifications",
                type: "error",
                title: "Fout opgetreden",
                text: "Oeps er ging iets fout..",
              },
              2000
            )
            console.error(error)
          }
        })

      console.log("LINELINE", line)
      console.log("initialItem", this.initialitem)

      this.addItem()
    },

    async fetchArea() {
      this.area = null

      await axios
        .get(`/areas/${this.area_id}`)
        .then((response) => {
          console.log(response.data)
          this.area = response.data.data
        })
        .catch(() => {
          this.$notify(
            {
              group: "notifications",
              type: "error",
              title: "Fout opgetreden",
              text: "Oeps er ging iets fout..",
            },
            2000
          )
        })
    },
  },

  computed: {
    isRootTable() {
      return !this.table?.attributes?.parent_id
    },

    isSubtableActive() {
      if (!(this.area?.attributes?.subtable_flag ?? false)) {
        return false
      }

      return this.table?.attributes?.total > 0 || this.table?.attributes?.subtable_count > 0
    },
  },
}
</script>

<template>
  <navigation-mobile-pos
    :title="tableName"
    @titleclicked="$refs.modalTableRename.open()"
  >
    <template
      #right
      v-if="isRootTable && isSubtableActive"
    >
      <button
        class="inline-flex items-center justify-center text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
        @click.prevent="$refs.subtableModal.open(table_id, table)"
      >
        <svg
          width="22"
          height="20"
          viewBox="0 0 22 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.5 19.5L16 18L19 15L16 12L17.5 10.5L22 15L17.5 19.5ZM6 18V16H2C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V9H18V2H2V14H15V16H13V18H6ZM9 12H11V9H14V7H11V4H9V7H6V9H9V12Z"
            fill="white"
          />
        </svg>
      </button>
    </template>

    <div class="flex justify-end">
      <a
        @click="tableClose()"
        class="text-white"
        aria-label="Close"
      >
        <svg
          class="h-8 w-8"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </a>
    </div>
  </navigation-mobile-pos>

  <div class="flex-1 h-mobile-screen">
    <swiper
      :modules="modules"
      :effect="'creative'"
      :initial-slide="1"
      :space-between="0"
      :slides-per-view="1"
      :threshold="0"
      :touch-release-on-edges="true"
      :edge-swipe-detection="true"
      :simulate-touch="false"
      :no-swiping-class="'no-swiping'"
      :creativeEffect="{
        prev: {
          shadow: true,
          translate: [0, 0, -400],
        },
        next: {
          shadow: true,
          translate: ['100%', 0, 0],
        },
      }"
      @swiper="(instance) => (swiper = instance)"
      class="h-full text-left"
    >
      <swiper-slide class="h-full bg-gray-900">
        <div class="h-full flex flex-col px-3">
          <loading-indicator
            v-show="!initialized"
            class="mt-64"
          />

          <TransactionItems
            v-if="initialized"
            :table="table"
            :closing="closing"
            @close-table="tableClose()"
            @reload-table="tableGet()"
            @edit-line-note="openLineNote"
          />
        </div>
      </swiper-slide>

      <swiper-slide class="h-full bg-gray-900">
        <div class="h-full flex flex-col">
          <ItemSelection
            :area_id="area_id"
            :table_id="table_id"
            @open-line-note="openLineNote"
          />
        </div>
      </swiper-slide>

      <swiper-slide class="h-full bg-gray-900">
        <div class="h-full flex flex-col px-3">
          <loading-indicator
            v-show="!initialized"
            class="mt-64"
          />

          <BasketItems
            v-if="initialized"
            :table="table"
            :closing="closing"
            @closeTable="tableClose()"
            @reloadTable="tableGet()"
            @edit-line-note="openLineNote"
          />
        </div>
      </swiper-slide>
    </swiper>
  </div>

  <!-- modals -->
  <modal-notes ref="modalNotes" />
  <modal-table-rename
    ref="modalTableRename"
    @saved="tableGet()"
  />

  <SubtableModal
    ref="subtableModal"
    :area="area"
    :area_id="area_id"
    :parent-table="table"
    :show-parent-selection="false"
  />
</template>
